<template>
	<div class="row comfort">
		<div class="col-1 d-none d-md-block"></div>
		<div class="col-12 col-md-6">
			<h3>
				ЗБЕРІГАТИ РЕЧІ<br />
				В МЕНЕ ЗРУЧНО
			</h3>
			<p>Тому, що</p>
			<div class="row">
				<div class="col clearfix">
					<div class="point" style="width: 374px">
						<p>
							Можна щось тимчасово сховати від дітей)) Наприклад, подарунки на
							День Народження!
						</p>
					</div>
					<div class="point" style="width: 278px">
						<p>Завдяки фото не забудеш, що відправив на зберігання</p>
					</div>
					<div class="point" style="width: 216px">
						<p>Вдома хлам (сезонні речі) не мозолить очі</p>
					</div>
					<div class="point" style="width: 278px">
						<p>Економія часу та енергії на пакування та доставку на склад</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-5 clearfix">
			<img src="~@/assets/images/cat_in_box.png" alt="Cat with smartphone" />
		</div>
	</div>
</template>

<script>
export default {
	name: "ComfortSection",
};
</script>

<style lang="scss" scoped>
.comfort {
	h3 {
		text-align: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 34px;
		color: #333333;
	}
	p {
		text-align: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #828282;
	}
	.point {
		position: relative;
		background: #ffffff;
		opacity: 0.9;
		border-radius: 10px;
		padding: 15px;
		margin: 7px 10px;
		border-left: #efab23;
		border-left-width: 5px;
		border-left-style: solid;
		height: 126px;
		float: left;
		background: rgba(255, 255, 255, 0.4);
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
		backdrop-filter: blur(20px);
		border-radius: 24px;
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 43px;
			left: -17px;
			width: 29px;
			height: 43px;
			background-image: url("~@/assets/images/star.png");
		}
		h3 {
			margin: unset;
			font-family: "Nunito";
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 110%;
		}
		p {
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			color: #333333;
			padding: 15px 10px;
		}
	}
	img {
		margin-top: -100px;
		width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.point {
		width: 100% !important;
		height: auto !important;
	}
	img {
		margin-top: 10px !important;
		width: 60vw !important;
	}
}
</style>
