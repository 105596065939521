<template lang="ua">
    <div class="row faq">
        <div class="col-1 d-none d-md-block"></div>
		<div class="col-12 col-md-6 d-block d-md-none w-100 clearfix">
            <img src="~@/assets/images/cat_question_mob.png" alt="Cat questions">
        </div> 
        <div class="col-12 col-md-5 left-column">
                <div class="header">
                    <div class="text-block">
                        <h3>ЗАПИТАЙ МЕНЕ ЩОСЬ</h3>
                        <p>Та спершу переглянь відповіді нижче</p>
                    </div>
                    <div class="button-question">
                        <button @click="" class="btn btn-primary" >Задати питання</button>
                    </div>
                </div>
                <div class="body clearfix">
                    <div class="question" v-for="question in questions" :key="question.id">
                        <div class="question-header"  @click="toggle(question.id)">
                                <h3>{{ question.title }}</h3>
                                <img v-if="question.expand" src="~@/assets/icons/question_up.png" />
                                <img v-else  src="~@/assets/icons/question_down.png" />
                        </div>
                        <transition name="slide">
                        <div class="question-body" v-if="question.expand">
                            <p>{{ question.answer }}</p>
                        </div>
                        </transition>
                    </div>
                </div>
        </div>
        <div class="col-12 col-md-6 d-none d-md-block right-column">
            <img src="~@/assets/images/cat_question.png" alt="Cat questions">
        </div> 
    </div>
</template>
<script>
export default {
	name: "FAQSection",
	data() {
		return {
			questions: [
				{
					title: "Хто такий Зберігач",
					answer:
						"Ми компанія, яка входить в групу компаній Комора, яка працює на ринку України з 2016 року. Комора має репутацію найкращого та надійного сервісу зберігання речей в Києві. Наш новий сервіс Зберігач , це новий рівень зберігання в 1 клік.",
					expand: false,
					id: 0,
				},
				{
					title: "Можна я сам привезу/заберу свої речі",
					answer:
						"У будні дні у нас працює пункт видачі на складі, де ви можете забрати свої речі самостійно. Для цього треба замовити послугу повернення речей на сайті або телефоном та вказати, що ви плануєте це зробити самостійно.  Будь ласка, перевірте чи зручно вам дістатися до нашого пункту видачі, який знаходиться біля метро Видубичі.",
					expand: false,
					id: 1,
				},
				{
					title: "Де знаходиться ваш склад",
					answer:
						"Наш склад знаходиться в Києві  за адресою Інженерна, 1. Це близенько до метро, одразу за Сільпо на Видубичах. На території діє суворий пропускний режим, доступ на склад мають лише працівники",
					expand: false,
					id: 2,
				},
				{
					title: "В яких умовах зберігаються речі",
					answer:
						"Ми зберігаємо речі на теплому та сухому складі. На складі ми розміщуємо речі на палетах чи спеціальних стелажах. Кожну палету ми упаковуємо в плівку та присвоюємо QR-код для швидкої навігації і стандартизації.",
					expand: false,
					id: 3,
				},
				{
					title: "Як швидко ви приїдете забрати речі",
					answer:
						"Після узгодження всіх деталей щодо пакування та кількості речей ми готові приїхати на наступний день. Якщо вам потрібна доставка день-в-день спробуємо організувати терміновий забір речей. Ми завжди відкрити та готові допомогти нашим клієнтам на скільки будемо мати змоги",
					expand: false,
					id: 4,
				},
				{
					title: "Ви працюєте по договору",
					answer:
						"Так ми працюємо з фізичними та юридичними особами і заключаємо договір з видачею всіх необхідних платіжних документів. Договір з фізичними особами заключаємо на правах оферти і підписуємо он-лайн.",
					expand: false,
					id: 5,
				},
				{
					title: "Чи треба платити одразу за весь строк зберігання",
					answer:
						"Перший платіж покриває доставку речей на склад та термін зберігання на 1 місяць. Надалі ви сплачуєте за зберігання щомісячно наперед. Звичайно, у нас можуть бути заохочувальні акції при розрахунку наперед або за ваш «зберігальний» стаж.",
					expand: false,
					id: 6,
				},
				{
					title: "Які умови повернення",
					answer:
						"Ви обираєте в своєму додатку речі які вам потрібно повернути і на наступний день в узгоджений час ми доставляємо вам речі. Якщо терміни припадають на святкові та вихідні дні - термін доставки може бути збільшено. Прохання враховувати коли клікаєте на повернення, завжди можна звернутися до менеджера і обговорити деталі.",
					expand: false,
					id: 7,
				},
			],
		};
	},
	methods: {
		toggle(index) {
			this.questions[index].expand = !this.questions[index].expand;
		},
	},
};
</script>
<style lang="scss" scoped>
.faq {
	.left-column {
		margin-top: 100px;
		.header {
			background: #ffffffff;
			border-left: 5px solid #efab23;
			opacity: 0.9;
			border-radius: 10px;
			min-height: 90px;
			padding-left: 15px;
			.text-block {
				float: left;
				margin: 10px 0;
				h3 {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 700;
					font-size: 28px;
					line-height: 34px;
					text-transform: uppercase;
					color: #333333;
				}
				p {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 22px;
					color: #828282;
				}
			}
			.button-question {
				float: right;
				margin: 20px 0;
				button {
					background: #efab23;
					box-shadow: 0px 0px 20px rgba(239, 171, 35, 0.49);
					border-radius: 30px;
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 22px;
					color: #ffffff;
					padding: 10px 25px;
					border-style: none;
				}
			}
		}
		.body {
			margin-top: 25px;
			width: 100%;
			.question {
				margin-top: 25px;
				.question-header {
					background: rgba(255, 255, 255, 0.4);
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
					backdrop-filter: blur(10px);
					border-radius: 24px;
					padding: 7px 25px;
					min-height: 70px;
					h3 {
						font-family: "Montserrat";
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 22px;
						color: #333333;
						float: left;
						margin-top: 18px;
					}
					img {
						margin-top: 22px;
						width: 19px;
						height: 10px;
						float: right;
					}
				}
				.question-body {
					transform-origin: top;
					transition: transform 0.4s ease-in-out;
					padding: 7px 25px;
					p {
						font-family: "Nunito";
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 140%;
						color: #4f4f4f;
						text-align: justify;
						margin-top: 15px;
					}
				}
			}
		}

		.slide-enter,
		.slide-leave-to {
			transform: scaleY(0);
		}
	}
	.right-column {
		img {
			width: 100%;
			margin-top: -200px;
			float: right;
		}
	}
}

@media screen and (max-width: 768px) {
	.header {
		background: unset !important;
		border-left: unset !important;
		.text-block,
		.button-question {
			float: unset !important;
		}
	}

	.question {
		.question-header {
			h3 {
				text-align: left !important;
				width: 90%;
			}
		}
	}
}
</style>
