<template>
	<footer>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="logo">ЗБЕРІГАЧ</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="footer-nav">
					<ul>
						<li><a href="#">Контакти</a></li>
						<li><a href="#">Способи оплати</a></li>
						<li><a href="#">Адреси складів</a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
export default {
	name: "FooterSection",
};
</script>
<style lang="scss">
footer {
	min-height: 80px;
	background-color: #592aff;
	.logo {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 140%;
		color: #ffffff;
		float: left;
		float: left;
		margin: 21px 50px;
	}
	ul {
		li {
			float: left;
			margin: 10px 25px;
			list-style: none;

			a {
				color: #fff;
				text-decoration: none;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
			}
			a:hover {
				text-shadow: 0px 1px 1px #6921ad;
				color: #f0e0ff;
				text-decoration: underline;
			}
		}
	}
}
</style>
