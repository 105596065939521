<template>
	<div class="row promo">
		<div class="col-1 d-none d-md-block"></div>
		<div class="col-12 col-md-7 left">
			<img src="~@/assets/images/gift.png" alt="Gift" />
			<div class="desc">
				<h3>Воу!</h3>
				<p>
					Дарую перший місяць зберігання без оплати. <a href="#">Спробуй!</a>
				</p>
			</div>
		</div>
		<div class="col-12 col-md-3 right">
			<button>Cпробувати</button>
		</div>
		<div class="col-1 d-none d-md-block"></div>
	</div>
</template>

<script>
export default {
	name: "PromoSection",
};
</script>

<style lang="scss" scoped>
.promo {
	max-height: 122px;
	background: rgba(255, 255, 255, 0.4);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	.left {
		text-align: left;
		display: flex;
		align-items: center;
		.desc {
			margin-left: 25px;
		}
		img {
			margin-top: -20px;
			float: left;
		}
		h3 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 39px;
			text-transform: uppercase;
			color: #333333;
		}
		p {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: #828282;
		}
		a {
			color: #592aff;
			text-decoration: none;
		}
	}
	.right {
		text-align: right;
		display: flex;
		align-items: center;
		button {
			background: #592aff;
			box-shadow: 0px 0px 20px rgba(89, 42, 255, 0.49);
			border-radius: 30px;
			color: #ffffff;
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			padding: 10px 20px;
		}
	}
}

@media screen and (max-width: 768px) {
	.promo {
		margin-top: 100px;
		max-height: unset;
		.left {
			display: block !important;
			img {
				float: none;
				text-align: center;
				margin-left: calc(50% - 79px);
				margin-top: -79px;
			}
			.desc {
				text-align: center;
				margin-top: 25px;
			}
		}
		.right {
			text-align: center;
			display: block;
			button {
				margin: 25px 0;
			}
		}
	}
}
</style>
