<template>
	<div class="hero-section row">
		<NavMenu />

		<div class="row">
			<div class="col-sm-12 col-md-6 hero-title clearfix">
				<img
					class="hero-deco back ellipse"
					src="~@/assets/images/ellipse.png"
					alt="ellipse"
				/>
				<img
					class="hero-deco front box"
					src="~@/assets/images/box.png"
					alt="ellipse"
				/>
				<img
					class="hero-deco front truck"
					src="~@/assets/images/box_truck.png"
					alt="ellipse"
				/>
				<img
					class="hero-deco back big-box"
					src="~@/assets/images/big_box.png"
					alt="ellipse"
				/>
				<h1>ЗБЕРІГАННЯ<br />РЕЧЕЙ</h1>
				<h2>ІЗ ДОСТАВКОЮ</h2>
				<h3>
					Спакую та вивезу речі, збережу їх на теплому складі та поверну, коли
					вони тобі знадобляться
				</h3>
				<div class="hint-block">
					<img src="~@/assets/icons/spark_bullet.png" alt="spark" />
					<p>
						Надішли мені перелік або фото речей і я скажу тобі точну вартість за
						20 хвилин
					</p>
				</div>
				<button>Дізнатись вартість</button>
			</div>
			<div class="col-sm-12 col-md-6 align-left">
				<img width="100%" src="~@/assets/images/hero_screen_cat.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import NavMenu from "./NavMenu.vue";
export default {
	name: "HeroSection",
	components: {
		NavMenu,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hero-section {
	background-image: url("~@/assets/images/hero_screen_bg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 20px;
	.ellipse {
		top: 130px;
		left: 31px;
	}
	.box {
		top: 226px;
		left: 467px;
	}
	.big-box {
		top: 235px;
		left: 45px;
	}
	.truck {
		top: 270px;
		left: 390px;
	}
	.hero-deco {
		position: absolute;
		width: auto;
	}
	.back {
		z-index: 0;
	}
	.front {
		z-index: 1;
	}
	.hero-title {
		padding-top: 100px;
		padding-left: 100px;
		text-align: left;
		h1 {
			position: relative;
			font-family: "Montserrat", sans-serif;
			font-weight: 700;
			font-size: 60px;
			line-height: 120%;
			color: #ffffff;
			z-index: 1;
		}
		h2 {
			position: relative;
			font-family: "Montserrat", sans-serif;
			font-weight: 600;
			font-size: 30px;
			line-height: 120%;
			color: #ffffff;
			z-index: 1;
		}
		h3 {
			font-family: "Nunito", sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			line-height: 120%;
			color: #ffffff;
			max-width: 400px;
			margin-top: 30px;
		}
		.hint-block {
			min-height: 40px;
			margin: 30px;
			p {
				font-family: "Nunito", sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #ffffff;
				float: left;
				max-width: 300px;
				padding-left: 15px;
			}
			img {
				float: left;
			}
		}
		button {
			float: none;
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			color: #ffffff;
			padding: 18px 34px;
			width: 248px;
			height: 58px;
			background: #efab23;
			box-shadow: 0px 0px 20px rgba(239, 171, 35, 0.49);
			border-radius: 30px;
			border: none;
		}
	}
}

@media screen and (max-width: 992px) {
	.hero-title {
		padding-top: 100px;
		padding-left: 100px;
		text-align: left;
		h1 {
			font-size: 40px !important;
		}
		h2 {
			font-size: 24px !important;
		}
		h3 {
			font-size: 16px !important;
		}
		.hint-block {
			min-height: 40px;
			margin: 0 !important;
			p {
				font-size: 12px !important;
				padding-left: 40px !important;
				float: none !important;
			}
			img {
				float: left;
			}
		}
		button {
			font-size: 14px !important;
		}
	}
}

@media screen and (max-width: 768px) {
	.hero-section {
		background-image: url("~@/assets/images/hero_bg_mobile.png");
		background-size: 100% 100%;
		background-position: 0 -100px;
		padding: 20px;
		.ellipse {
			width: 100%;
			height: auto;
			top: 124px !important;
			left: -99px !important;
		}
		.box {
			top: 256px !important;
			left: 320px !important;
		}
		.big-box {
			top: 235px;
			left: -39px !important;
		}
		.truck {
			top: 212px !important;
			left: 335px !important;
		}
		.hero-deco {
			position: absolute;
			width: auto;
		}
		.back {
			z-index: 0;
		}
		.front {
			z-index: 1;
		}
		.hero-title {
			padding-top: 0 !important;
			padding-left: 0 !important;
			text-align: center !important;
			h1 {
				font-size: 32px !important;
			}
			h2 {
				font-size: 16px !important;
			}
			h3 {
				font-size: 12px !important;
			}
			.hint-block {
				min-height: 40px;
				margin: 0 !important;
				p {
					font-size: 10px !important;
					padding-left: 40px !important;
					float: none !important;
				}
				img {
					float: left;
				}
			}
			button {
				font-size: 12px !important;
			}
		}
	}
}
</style>
