<template>
	<div class="row my-5">
		<div class="d-none d-md-block col-md-2"></div>
		<div class="col-12 col-md-8">
			<div class="social row my-5 p-4">
				<div class="col-12 col-md-6 col-lg-8">
					<h3>Потрібно щось інше? Пиши! Мяу</h3>
					<p>
						Більше варіантів зберігання або особливий розмір бокса розрахую для
						тебе персонально у чаті
					</p>
				</div>
				<div class="col-6 col-md-3 col-lg-2">
					<a href="https://t.me/+380689556008" class="telegram-btn button">
						<span
							><img
								class="icon"
								src="~@/assets/icons/telegram.png"
								alt="Telegram"
						/></span>
						<span>Telegram</span>
					</a>
				</div>
				<div class="col-6 col-md-3 col-lg-2">
					<a href="viber://chat?number=+380689556008" class="viber-btn button">
						<span
							><img class="icon" src="~@/assets/icons/viber.png" alt="Viber"
						/></span>
						<span>Viber</span>
					</a>
				</div>
			</div>
		</div>
		<div class="d-none d-md-block col-md-2"></div>
	</div>
</template>

<script>
export default {
	name: "SocialSection",
};
</script>

<style lang="scss" scoped>
.social {
	border: 1px solid #dedede;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.07));
	border-radius: 18px;
	h3 {
		text-align: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 34px;
		color: #333333;
	}
	p {
		text-align: left;
		font-family: "Nunito";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #828282;
	}
}

.button {
	display: block;
	text-decoration: none;
	padding: 7px;
	border-radius: 7px;
	margin-top: calc(50% - 38px);
	img.icon {
		float: left;
	}
	span {
		text-align: center;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #ffffff;
	}
}
.telegram-btn {
	background: #1e96c8;
}
.viber-btn {
	background: #7c529e;
}
</style>
