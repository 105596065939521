<template>
	<div class="container-fluid home-page">
		<HeroSection />
		<HowWorkSection />
		<TariffsSection />
		<SocialSection />
		<ErgonomicsSection />
		<ComfortSection />
		<PromoSection />
		<ReviewsSection />
		<QuestionSection />
		<FAQSection />
		<FooterSection />
	</div>
</template>

<script>
import HeroSection from "./components/HeroSection.vue";
import HowWorkSection from "./components/HowWorkSection.vue";
import TariffsSection from "./components/TariffsSection.vue";
import SocialSection from "./components/SocialSection.vue";
import ErgonomicsSection from "./components/ErgonomicsSection.vue";
import ComfortSection from "./components/ComfortSection.vue";
import PromoSection from "./components/PromoSection.vue";
import ReviewsSection from "./components/ReviewsSection.vue";
import QuestionSection from "./components/QuestionSection.vue";
import FAQSection from "./components/FAQSection.vue";
import FooterSection from "./components/FooterSection.vue";

export default {
	name: "App",
	components: {
		HeroSection,
		HowWorkSection,
		TariffsSection,
		SocialSection,
		ErgonomicsSection,
		ComfortSection,
		PromoSection,
		ReviewsSection,
		QuestionSection,
		FAQSection,
		FooterSection,
	},
};
</script>

<style>
.home-page {
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(
		315deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(245, 232, 252, 0.6786064767703957) 48%,
		rgba(255, 255, 255, 1) 100%
	);
	background: -webkit-linear-gradient(
		315deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(245, 232, 252, 0.6786064767703957) 48%,
		rgba(255, 255, 255, 1) 100%
	);
	background: linear-gradient(
		315deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(245, 232, 252, 0.6786064767703957) 48%,
		rgba(255, 255, 255, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
	background-repeat: repeat-y;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow-x: hidden;
}
</style>
