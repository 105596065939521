<template>
	<div class="row tariffs">
		<div class="row">
			<div class="col">
				<div class="title">
					<img src="~@/assets/images/bright_left.png" alt="" />
					<h3>ХОЧУ МІРЯТИ…</h3>
					<img src="~@/assets/images/bright_right.png" alt="" />
				</div>
				<div class="description">
					<p>Тарифи</p>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-2 d-sm-none d-md-block"></div>
			<div class="col-12 col-sm-6 col-md-4 my-2">
				<div class="items">
					<h3>У ШТУКАХ</h3>
					<div class="options clearfix">
						<div>
							<p>Коробка-бокс 50х50х30 →</p>
							<p>Шини →</p>
						</div>
						<div>
							<p>Сноуборд/Лижі →</p>
							<p>Велосипед →</p>
						</div>
					</div>
					<div class="services">
						<ul>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Безкоштовна перша доставка на склад</p>
							</li>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Доставка-повернення на наступний день</p>
							</li>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Безкоштовні пластикові пломбованіі бокси (макс. 5)</p>
							</li>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Зручна довідправка 300 грн поїздка + 50 грн річ</p>
							</li>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Охоронюваний теплий склад</p>
							</li>
							<li>
								<img src="~@/assets/images/purple_paw.png" alt="spark" />
								<p>Безкоштовне страхування 1000 грн/бокс</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4 my-2">
				<div class="volume">
					<h3>У КУБІЧНИХ МЕТРАХ</h3>
					<div class="options clearfix">
						<div>
							<p>1 метр →</p>
							<p>2 метри →</p>
						</div>
						<div>
							<p>3 метри →</p>
							<p>500 грн →</p>
						</div>
					</div>
					<div class="services">
						<ul>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Безкоштовна доставка пакувальних матеріалів</p>
							</li>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Доставка-повернення за 2 дні</p>
							</li>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Безкоштовні картонні коробки (макс. 10)</p>
							</li>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Зручне повернення від 500 грн і вище</p>
							</li>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Охоронюваний теплий склад</p>
							</li>
							<li>
								<img src="~@/assets/images/orange_paw.png" alt="spark" />
								<p>Безкоштовне страхування 1000 грн/бокс</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-2 d-sm-none d-md-block"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TariffsBlock",
};
</script>

<style lang="scss" scoped>
.tariffs {
	padding-top: 50px;
	.title {
		display: block;
		clear: both;
		width: 100%;
		min-height: 48px;
		height: auto;
		margin-left: calc(50% - 160px);
		img {
			float: left;
		}
		h3 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 28px;
			line-height: 34px;
			color: #333333;
			padding: 0px 10px;
			float: left;
		}
	}
	.description {
		display: block;
		clear: both;
		width: 100%;
		min-height: 48px;
		height: auto;
		p {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
			text-align: center;
			color: #333333;
		}
	}
	.items {
		background: #592aff;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
		border-radius: 24px;
		padding: 10px;
		width: 100%;
		height: 100%;
		h3 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 28px;
			line-height: 34px;
			color: #ffffff;
			margin: 20px 0px;
		}
		.services {
			background: #ffffff;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
			border-radius: 18px;
			padding: 10px;
			ul {
				padding: 10px;
				text-align: center;
				li {
					list-style: none;
					img {
						float: left;
					}
				}
			}
		}
		.options {
			margin-top: 20px;
			text-align: center;
			height: auto;
			min-height: 70px;
			p {
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				color: #ffffff;
				background: #4015d7;
				border-radius: 10px;
				padding: 5px 10px;
				margin: 5px;
				float: left;
				display: inline-block;
			}
		}
	}
	.volume {
		background: #efab23;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
		border-radius: 24px;
		padding: 10px;
		width: 100%;
		height: 100%;
		h3 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 28px;
			line-height: 34px;
			color: #ffffff;
			margin: 20px 0px;
		}
		.services {
			background: #ffffff;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
			border-radius: 18px;
			ul {
				padding: 10px;
				text-align: center;
				li {
					list-style: none;
					img {
						float: left;
					}
				}
			}
		}
		.options {
			margin-top: 20px;
			text-align: center;
			height: auto;
			min-height: 70px;
			p {
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				color: #ffffff;
				background: #dd9a15;
				border-radius: 10px;
				padding: 5px 10px;
				margin: 5px;
				float: left;
				display: inline-block;
			}
		}
	}
}
</style>
