<template>
	<div class="how-work row">
		<div class="row">
			<div class="col">
				<div class="title m-5 clearfix">
					<img src="~@/assets/icons/line_bullet.png" alt="" />
					<h3>ЯК ПРАЦЮЄ СЕРВІС?</h3>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-2 d-none d-xl-block"></div>
			<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 block-parent">
				<div class="block">
					<div class="icon">
						<img src="~@/assets/images/yellow_box.png" alt="" />
					</div>
					<h3>Спаковка</h3>
					<p>
						Спакуйте речі самостійно, або замовте моїх муверів одразу із
						коробками. Вони такі котики!
					</p>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 block-parent">
				<div class="block">
					<div class="icon">
						<img src="~@/assets/images/yellow_truck.png" alt="" />
					</div>
					<h3>Трансфер</h3>
					<p>
						Оберіть зручну дату і час. Моя кото-команда забере ваші речі, навіть
						меблі, і дбайливо привезе мені!
					</p>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 block-parent">
				<div class="block">
					<div class="icon">
						<img src="~@/assets/images/warehouse.png" alt="" />
					</div>
					<h3>Зберігання</h3>
					<p>
						Я збережу ваші речі в теплі і комфорті на складі у Києві. Завантажу
						фото речей до вашого персонального кабінету
					</p>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 block-parent">
				<div class="block">
					<div class="icon">
						<img src="~@/assets/images/box_return.png" alt="" />
					</div>
					<h3>Повернення</h3>
					<p>
						Подивіться фото речей в персональному кабінеті. Заплануйте зворотню
						доставку одним дотиком
					</p>
				</div>
			</div>
			<div class="col-xl-2 d-none d-xl-block"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HowWork",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.how-work {
	.block-parent {
		.title {
			margin-left: 100px;
			img {
				float: left;
				padding: 15px;
			}
			h3 {
				float: left;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 700;
				font-size: 28px;
				line-height: 34px;
			}
		}
		.block {
			background-image: url("~@/assets/images/asym_rect.png");
			background-size: cover;
			padding: 0px 32px;
			width: 270px;
			height: 216px;
		}
		.icon {
			background: #f9f9f9;
			box-shadow: 0px 4px 20px #00000012;
			border-radius: 100%;
			padding: 15px;
			width: 90px;
			height: 90px;
		}
		h3 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			color: #333333;
		}
		p {
			font-family: "Nunito";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #333333;
		}
	}
}

@media screen and (max-width: 576px) {
	.block {
		margin-left: calc(50% - 135px);
		float: none;
	}
}
</style>
