<template>
	<div class="row ergonomics">
		<div class="col-12 col-md-5">
			<img
				class="cat"
				src="~@/assets/images/cat_smartphone.png"
				alt="Cat with smartphone"
			/>
		</div>
		<div class="col-12 col-md-7">
			<h3>ВСІ ВАШІ РЕЧІ<br />НА ЕКРАНІ СМАРТФОНА</h3>
			<p>
				Фотографії всього, що ви відправили на зберігання,<br />доступні у
				вашому персональному кабінеті
			</p>
			<div class="row">
				<div class="col">
					<div class="block left">
						<h3>Повернути речі назад легко</h3>
						<div class="point">
							<h3>Повернення на наступний день</h3>
							<p>для поштучного зберігання</p>
						</div>
						<div class="point">
							<h3>Повернення за 2 дні</h3>
							<p>для зберігання у куб метрах</p>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="block right">
						<h3>Керуйте всіма своїми речами</h3>
						<p>
							Коли щось знову знадобиться, замовте повернення, і мої Кото-мувери
							привезуть його в будь-яку точку Києва
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ErgonomicsSection",
};
</script>

<style lang="scss" scoped>
.ergonomics {
	h3 {
		text-align: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 34px;
		color: #333333;
	}
	p {
		text-align: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #828282;
	}
	.cat {
		margin-top: -100px;
		width: 100%;
	}
}
.block {
	&.left {
		background-image: url("~@/assets/images/asym_rect_left_long.png");
		width: 311px;
		height: 320px;
	}
	&.right {
		width: 285px;
		height: 240px;
		background-image: url("~@/assets/images/asym_rect_right_short.png");
	}
	&:before {
		content: url("~@/assets/images/circle_with_rings.png");
		display: block;
		position: relative;
		top: -30px;
		left: -59px;
		width: 93px;
		height: 93px;
	}
	background-size: cover;
	padding: 0px 32px;
	h3 {
		margin-top: -40px;
		font-size: 22px;
		line-height: 27px;
	}
	p {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #333333;
	}
	.icon {
		background: #f9f9f9;
		box-shadow: 0px 4px 20px #00000012;
		border-radius: 100%;
		padding: 15px;
		width: 90px;
		height: 90px;
	}
	.point {
		background: #ffffff;
		opacity: 0.9;
		border-radius: 10px;
		padding: 7px;
		margin: 7px 0px;
		border-left: #592aff;
		border-left-width: 5px;
		border-left-style: solid;
		h3 {
			margin: unset;
			font-family: "Nunito";
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 110%;
		}
		p {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #828282;
		}
	}
}
@media screen and (max-width: 768px) {
	.cat {
		width: 60vw !important;
		margin: 25px auto !important;
	}
}
</style>
