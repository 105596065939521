<template>
	<div class="row reviews-section">
		<div class="col-1 d-none d-md-block"></div>
		<div class="col-12 col-md-10">
			<div class="row">
				<div class="col-12 col-md-6">
					<div class="title">
						<img src="~@/assets/icons/line_bullet.png" alt="" />
						<h3>ВІДГУКИ КЛІЄНТІВ</h3>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="all-rating">
						<p>
							Середня оцінка <b>{{ allRating }}</b>
						</p>
						<div class="stars">
							<img
								v-for="i in 5"
								:key="i"
								:src="getImgUrl(starRating(i, allRating))"
								alt="star"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="slider col-12">
					<div @click="step(-1)" class="nav prev">
						<img src="~@/assets/icons/slider_arrow_left.png" alt="arrow" />
					</div>
					<div class="reviews">
						<div
							class="review-block"
							:style="sliderPosition(index)"
							v-for="(review, index) in reviews"
							:key="review.name"
						>
							<div class="header">
								<img
									:src="getImgUrl(review.avatar)"
									class="avatar"
									alt="avatar"
								/>
								<div class="name">{{ review.name }}</div>
								<div class="rating">
									<img
										v-for="i in 5"
										:key="i"
										:src="getImgUrl(starRating(i, review.stars))"
										alt="star"
									/>
								</div>
							</div>
							<div class="body">
								{{ review.text }}
							</div>
							<div class="footer">
								<div class="date">{{ review.date }}</div>
							</div>
						</div>
					</div>
					<div @click="step(1)" class="nav next">
						<img src="~@/assets/icons/slider_arrow_right.png" alt="arrow" />
					</div>
				</div>
			</div>
		</div>
		<div class="col-1 d-none d-md-block"></div>
	</div>
</template>

<script>
export default {
	name: "ReviewsSection",
	computed: {
		allRating() {
			return (
				this.reviews.reduce((a, b) => parseInt(a) + parseInt(b.stars), 0) /
				this.reviews.length
			).toFixed(2);
		},
	},
	data() {
		return {
			sliderStep: 0,
			reviews: [
				{
					name: "Анастасія Моргунова",
					avatar: "images/avatar_1.png",
					text: "Зберігач  став порятунком для мене! Вирішила зробити ремонт у батьків у квартирі, показати їм, що можна жити по-дугому, без мотлоху на балконах. Тема розхламлення зараз дуже актуальна. Розгрібала балкони довго, дещо пішло в брухт, а все найцінніше відвезли на склад. Після ремонту...",
					stars: 5,
					date: "2020-10-10",
				},
				{
					name: "Андрій Семеренко",
					avatar: "images/avatar_2.png",
					text: "Договір та оплату можна оформити віддалено. Я все зробив інтернетом, а батьки тільки завезли все обладнання на склад. Часу витратили п'ять хвилин, натомість зважився величезний біль голови зі зберіганням обладнання.",
					stars: 4,
					date: "2020-10-11",
				},
				{
					name: "Олег Палич",
					avatar: "images/avatar_3.png",
					text: "Задумано та виконано просто чудово! Дуже допомогли у скрутну хвилину, коли терміново потрібно було вивезти речі на час ремонту. Тепер став незмінним клієнтом. Сухо, тепло, світло, що ще треба для зберігання? І доступний у будь-який час, а це теж важливо. ",
					stars: 5,
					date: "2020-10-12",
				},
				{
					name: "Анастасія Моргунова",
					avatar: "images/avatar_1.png",
					text: "Зберігач  став порятунком для мене! Вирішила зробити ремонт у батьків у квартирі, показати їм, що можна жити по-дугому, без мотлоху на балконах. Тема розхламлення зараз дуже актуальна. Розгрібала балкони довго, дещо пішло в брухт, а все найцінніше відвезли на склад. Після ремонту...",
					stars: 5,
					date: "2020-10-10",
				},
				{
					name: "Андрій Семеренко",
					avatar: "images/avatar_2.png",
					text: "Договір та оплату можна оформити віддалено. Я все зробив інтернетом, а батьки тільки завезли все обладнання на склад. Часу витратили п'ять хвилин, натомість зважився величезний біль голови зі зберіганням обладнання.",
					stars: 4,
					date: "2020-10-11",
				},
				{
					name: "Олег Палич",
					avatar: "images/avatar_3.png",
					text: "Задумано та виконано просто чудово! Дуже допомогли у скрутну хвилину, коли терміново потрібно було вивезти речі на час ремонту. Тепер став незмінним клієнтом. Сухо, тепло, світло, що ще треба для зберігання? І доступний у будь-який час, а це теж важливо. ",
					stars: 5,
					date: "2020-10-12",
				},
			],
		};
	},
	methods: {
		getImgUrl(img) {
			return require(`@/assets/${img}`);
		},
		starRating(i, stars) {
			return i <= Math.floor(stars)
				? "icons/filled_star.png"
				: "icons/empty_star.png";
		},
		step(i) {
			const step = this.sliderStep;
			if (step + i <= 0) {
				this.sliderStep = 0;
				return;
			}
			if (step + i >= this.reviews.length - 2) {
				this.sliderStep = this.reviews.length - 2;
				return;
			}
			this.sliderStep += i;
		},
		sliderPosition() {
			const reviewBlock = document.querySelectorAll(".review-block")?.[0];
			const reviewBlockWidth = reviewBlock?.offsetWidth + 24;
			return {
				transform: `translateX(${-this.sliderStep * reviewBlockWidth}px)`,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.reviews-section {
	margin: 50px 0;
}
.title {
	margin-left: 100px;
	img {
		float: left;
		padding: 15px;
	}
	h3 {
		float: left;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 34px;
	}
}
.all-rating {
	border: 1px solid #dedede;
	border-radius: 18px;
	padding: 10px 20px;
	display: inline-block;
	p {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #828282;
		margin-bottom: 0;
		b {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			text-align: center;
			color: #4f4f4f;
		}
	}
}
.slider {
	margin-top: 25px;
	.nav {
		background: #592aff;
		box-shadow: 0px 0px 20px rgba(89, 42, 255, 0.55);
		border-radius: 24px;
		width: 60px;
		height: 60px;
		float: left;
		transform: translateY(150px);
		cursor: pointer;
		img {
			width: 10px;
			height: 19px;
			margin: 0;
			position: relative;
			top: 50%;
			left: 50%;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
	.reviews {
		display: flex;
		flex-flow: row nowrap;
		overflow-x: hidden;
		width: calc(100% - 150px);
		float: left;
		.review-block {
			flex: 0 0 auto;
			float: left;
			width: calc(33% - 20px);
			height: 380px;
			margin: 12px;
			background: rgba(255, 255, 255, 0.4);
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
			backdrop-filter: blur(20px);
			border-radius: 24px;
			text-align: left;
			padding: 15px;
			transition: all 0.3s ease-in-out;
			.header {
				float: none;
				clear: both;
				min-height: 70px;
				.avatar {
					float: left;
					padding-right: 12px;
				}
			}
			.body {
				font-family: "Nunito";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 140%;
				color: #333333;
			}
			.footer {
				position: absolute;
				display: inline-block;
				bottom: 20px;
				left: 15px;
				.date {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 15px;
					color: #828282;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.reviews {
		width: calc(100% - 120px) !important;
		.review-block {
			width: calc(100% - 20px) !important;
		}
	}

	.body {
		font-size: 13px !important;
	}
}
</style>
