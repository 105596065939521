<template>
	<div class="row">
		<div class="col-1"></div>
		<div class="col-5"></div>
		<div class="col-5"></div>
		<div class="col-1"></div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
