<template>
	<div class="row">
		<div class="col-12 col-sm-6 ps-sm-5">
			<div class="row">
				<div class="col-6"><div class="logo">ЗБЕРІГАЧ</div></div>
				<div class="col-6">
					<div class="phone">
						<img src="~@/assets/icons/phone.png" alt="Phone" />
						<a href="tel:+380689556008">+38 068 955 60 08</a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6"></div>
	</div>
</template>

<script>
export default {
	name: "NavMenu",
};
</script>

<style lang="scss" scoped>
.logo {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 140%;
	color: #ffffff;
	float: left;
}
.phone {
	float: left;
	img {
		padding: 6px 10px;
		float: left;
	}
	a {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		padding: 6px 10px;
		color: #ffffff;
		float: left;
		text-decoration: none;
	}
}
@media screen and (max-width: 1100px) {
	.phone {
		img {
			padding: 8px 7px !important;
			width: 30px;
		}
		a {
			font-size: 10px !important;
			padding: 6px 0px !important;
		}
	}
}
</style>
